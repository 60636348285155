import { API } from "aws-amplify";
import { Form, FormField, Select, TextInput, Button } from "grommet";
import React from "react";
import { CreateExtensionSession } from "../graphql/mutations";
import ShortUniqueId from "short-unique-id";
import { useUser } from "../AuthContext";

export default function ExtensionSessionForm({ exams, setIsForm }) {
  const user = useUser();
  const shortUid = new ShortUniqueId({
    dictionary: "alphanum_lower",
    length: 6,
  });

  async function createSession({ value }, retries = 3) {
    try {
      const data = await API.graphql({
        query: CreateExtensionSession,
        variables: {
          input: {
            session_code: shortUid.rnd(),
            candidate_name: value.name,
            exam_name: value.exam.exam_name,
            status: "active",
            proctor: user.user.attributes.email,
            type: "ExtensionSession",
            exam_url: value.exam.exam_url,
            max_tabs: value.exam.max_tabs,
            keep_alive_interval: value.exam.keep_alive_interval,
            maximum_duration: value.exam.maximum_duration,
            events: [],
          },
        },
      });
      console.log(data);

      if (data.errors) {
        throw new Error("Error creating session");
      }

      window.open(
        `/extension/${data.data.createExtensionSession.session_code}`,
        "_blank"
      );
      setIsForm(false);
    } catch (error) {
      console.error(error);
      if (retries > 0) {
        console.warn(`Retrying... Attempts left: ${retries}`);
        await createSession({ value }, retries - 1);
      }
    }
  }

  return (
    <Form onSubmit={({ value }) => createSession({ value })}>
      <FormField name="name" label="Candidate Name" required>
        <TextInput id="name-input" name="name" />
      </FormField>
      <FormField name="exam" label="Exam" required htmlFor="exam-select">
        <Select
          options={exams}
          id="exam-select"
          name="exam"
          valueKey="id"
          labelKey="exam_name"
        />
      </FormField>
      <Button type="submit" primary label="Create Session" />
    </Form>
  );
}
