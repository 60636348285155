import React from "react";

export default function LCtest() {
  return (
    <div style={{ height: "85vh" }}>
      <iframe
        src="https://scribehow.com/page-embed/Sample_Learning_Center_Page__vxOoKlZsQ7C2laingKuLgQ"
        width="100%"
        height="100%"
        allowfullscreen
        frameborder="0"
      ></iframe>
    </div>
  );
}
