/* 
Component: Navigation Header, only shown for Authenticated Users
*/

import { Box, Button, DropButton, Header, Nav, Text } from "grommet";
import React from "react";
import logo from "../monitoredu-logo.svg";
import { User } from "grommet-icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../AuthContext";
import { Auth } from "aws-amplify";

function Navigator() {
  let navigate = useNavigate(); //used to navigate to different pages
  const myUser = useUser(); //user object from AuthContext

  return (
    <Box overflow="auto" align="center" flex="grow" fill responsive gap="none">
      <Header
        align="center"
        direction="row"
        flex={false}
        justify="between"
        gap="medium"
        fill="horizontal"
        background={{ color: "active", opacity: "medium" }}
        overflow="auto"
      >
        <Box
          align="center"
          justify="center"
          height="xxsmall"
          responsive
          pad="medium"
          wrap
          overflow="auto"
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "1fr", width: "250px" }}
          ></img>
        </Box>
        <Box
          align="center"
          justify="center"
          responsive
          wrap={false}
          overflow="auto"
        >
          <Nav
            align="center"
            flex
            direction="row"
            margin={{ right: "medium" }}
            gap="none"
            overflow="auto"
            pad="xsmall"
          >
            <Button
              label="Dashboard"
              onClick={() => navigate("/dashboard")}
              href="/dashboard"
            />
            <Button
              label="Mobile"
              onClick={() => navigate("/twmobile")}
              href="/twmobile"
            />
            <DropButton
              label="Webcams"
              dropAlign={{ top: "bottom" }}
              dropContent={
                <Box
                  align="stretch"
                  justify="center"
                  pad="xsmall"
                  name="dropContent"
                  width="small"
                >
                  <Button
                    label="Twilio Rooms"
                    onClick={() => navigate("/twiliosc")}
                    href="/twiliosc"
                  />
                  <Button
                    label="Twilio Link Form"
                    onClick={() => navigate("/twiliolinks")}
                    href="/twiliolinks"
                  />
                  <Button
                    label="Twilio Users"
                    onClick={() => navigate("/twiliousers")}
                    href="/twiliousers"
                  />
                  {/*
                  <Button
                    label="Webcam Rooms"
                    onClick={() => navigate("/webcams")}
                    href="/webcams"
                  />
                  <Button
                    label="Webcam Link Form"
                    onClick={() => navigate("/webcam-form")}
                    href="/webcam-form"
                  />
                  <Button
                    label="Webcam Users"
                    onClick={() => navigate("/webcam-users")}
                    href="/webcam-users"
                  />
              */}
                </Box>
              }
              dropProps={{ elevation: "xsmall", plain: false }}
              primary={false}
            />
            <DropButton
              label="Integrations"
              dropAlign={{ top: "bottom" }}
              dropContent={
                <Box
                  align="stretch"
                  justify="center"
                  pad="xsmall"
                  name="dropContent"
                  width="small"
                >
                  <Button
                    label="AAA Food Handlers"
                    onClick={() => navigate("/aaa-food-handlers")}
                    href="/aaa-food-handlers"
                  />
                  <Button
                    label="Backup Queue"
                    onClick={() => navigate("/proctorbackupqueue")}
                    href="/proctorbackupqueue"
                  />
                  <Button
                    label="CMS | ItemExperts"
                    onClick={() => navigate("/cms")}
                    href="/cms"
                  />
                  <Button
                    label="Colibri Proctor Form"
                    href="https://3520739.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=1206&deploy=1&compid=3520739&ns-at=AAEJ7tMQGVyp82liM-vfHyYd-L8hKOrYC9qzGs8xsc2LL3ZShlA"
                    target="_blank"
                  />
                  <Button
                    label="CRANE"
                    onClick={() => navigate("/crane")}
                    href="/crane"
                  />
                  <Button
                    label="Exam Codes"
                    onClick={() => navigate("/codes")}
                    href="/codes"
                  />
                  <Button
                    label="Extension Sessions"
                    onClick={() => navigate("/extension")}
                    href="/extension"
                  />
                  <Button
                    label="Google Rooms"
                    onClick={() => navigate("/google-rooms")}
                    href="/google-rooms"
                  />
                  <Button
                    label="Invigil"
                    onClick={() => navigate("/invigil")}
                    href="/invigil"
                  />
                  <Button
                    label="Payment Validation"
                    onClick={() => navigate("/payment-validation")}
                    href="/payment-validation"
                  />
                  <Button
                    label="Prolydian"
                    onClick={() => navigate("/prolydian-integration")}
                    href="/prolydian-integration"
                  />
                </Box>
              }
              dropProps={{ elevation: "xsmall", plain: false }}
              primary={false}
            />
            <DropButton
              dropAlign={{ top: "bottom" }}
              dropContent={
                <Box
                  align="stretch"
                  justify="center"
                  pad="xsmall"
                  name="dropContent"
                  width="small"
                >
                  <Text textAlign="center" size="small">
                    {myUser.user.attributes.email}
                  </Text>
                  <Button label="Sign Out" onClick={() => Auth.signOut()} />
                </Box>
              }
              dropProps={{ elevation: "xsmall", plain: false }}
              primary={false}
              icon={<User />}
              size="small"
            />
          </Nav>
        </Box>
      </Header>
    </Box>
  );
}

export default Navigator;
