import { Box, Layer, Tab, Tabs, Text, Button } from "grommet";
import React, { useEffect } from "react";
import ExtensionSessionForm from "../Components/ExtensionSessionForm";
import ExtensionSessions from "../Components/ExtensionSessions";
import { API } from "aws-amplify";
import { ListExtensionExams } from "../graphql/queries";
import { Close } from "grommet-icons";
import { useUser } from "../AuthContext";
import MyExtensionSessions from "../Components/ExtensionSessions";
import AllExtensionSessions from "./AllExtensionSessions";

export default function Extension() {
  const [exams, setExams] = React.useState([]);
  const [isForm, setIsForm] = React.useState(false);

  useEffect(() => {
    getExams();
  }, []);

  async function getExams() {
    try {
      const data = await API.graphql({
        query: ListExtensionExams,
      });

      setExams(data.data.listExtensionExams.items);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Box align="center" justify="center" pad="small" width="full">
      <Box width="full">
        <Box align="center" justify="center" pad="small" width="full">
          <Text weight="bold" size="large">
            SafeTest Live Extension Dashboard
          </Text>
          {isForm && (
            <Layer
              background="white"
              onEsc={() => setIsForm(false)}
              onClickOutside={() => setIsForm(false)}
            >
              <Box
                pad={{ horizontal: "medium", vertical: "small" }}
                width="medium"
              >
                <Button
                  icon={<Close />}
                  onClick={() => setIsForm(false)}
                  alignSelf="end"
                />
                <ExtensionSessionForm exams={exams} setIsForm={setIsForm} />
              </Box>
            </Layer>
          )}
          <Button label="Add Session" onClick={() => setIsForm(true)} />
        </Box>
        <Box width="full">
          <Tabs alignControls="start">
            <Tab title="My Sessions">
              <MyExtensionSessions />
            </Tab>
            <Tab title="All Sessions">
              <AllExtensionSessions />
            </Tab>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
}
