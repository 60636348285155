import { API } from "aws-amplify";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetExtensionSession } from "../graphql/queries";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Notification,
  Text,
} from "grommet";
import { onUpdateExtensionSession } from "../graphql/subscriptions";
import { UpdateExtensionSession } from "../graphql/mutations";

export default function ExtensionSession() {
  const [extensionSession, setExtensionSession] = React.useState({});
  const [showToast, setShowToast] = React.useState(false);
  const [showRestart, setShowRestart] = React.useState(false);
  const { id } = useParams();
  useEffect(() => {
    getExtensionSession();

    const sub = API.graphql({
      query: onUpdateExtensionSession,
      variables: {
        filter: {
          session_code: { eq: id },
        },
      },
    }).subscribe({
      next: (data) => {
        console.log(data);
        getExtensionSession();
      },
      error: (error) => console.error(error),
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  async function getExtensionSession() {
    try {
      const data = await API.graphql({
        query: GetExtensionSession,
        variables: {
          session_code: id,
        },
      });

      console.log(data);
      setExtensionSession(data.data.getExtensionSession);
    } catch (error) {
      console.error(error);
    }
  }

  async function finishSession() {
    try {
      const data = await API.graphql({
        query: UpdateExtensionSession,
        variables: {
          input: {
            session_code: extensionSession.session_code,
            status: "finished",
          },
        },
      });

      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function restartSession() {
    try {
      const data = await API.graphql({
        query: UpdateExtensionSession,
        variables: {
          input: {
            session_code: extensionSession.session_code,
            status: "active",
          },
        },
      });

      setShowRestart(true);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Box align="center" justify="center" width="full" pad="large">
      {showToast && (
        <Notification
          toast
          onClose={() => setShowToast(false)}
          title="Session Status Set To Finished"
          message="The extension will now stop sending events"
          status="critical"
        />
      )}
      {showRestart && (
        <Notification
          toast
          onClose={() => setShowToast(false)}
          title="Session Status Set To Active"
          message="The extension will now send events"
          status="normal"
        />
      )}
      <Card margin="small" pad="small" fill="horizontal" height="75vh">
        <CardBody>
          <Box border fill>
            {extensionSession.events && (
              <Box
                fill
                overflow="scroll"
                pad="small"
                direction="column"
                gap="small"
              >
                {extensionSession.events.map((event) => (
                  <Box
                    key={event.event}
                    direction="row"
                    gap="small"
                    align="center"
                    justify="between"
                    pad="small"
                    border={{ side: "bottom" }}
                  >
                    <Text>{event.event_timestamp}</Text>
                    <Text>{event.event}</Text>
                    <Text>{event.event_description}</Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </CardBody>
        <CardFooter>
          <Box
            align="start"
            justify="start"
            pad="small"
            direction="column"
            width="full"
          >
            <Text>Session Code: {extensionSession.session_code}</Text>
            <Text>Tester Name: {extensionSession.candidate_name}</Text>
            <Text>Exam Name: {extensionSession.exam_name}</Text>
            <Text>Status: {extensionSession.status}</Text>
          </Box>
          <Box>
            {extensionSession.status === "active" ? (
              <Button label="Finish Session" onClick={finishSession} />
            ) : (
              <Button label="Restart Session" onClick={restartSession} />
            )}
          </Box>
        </CardFooter>
      </Card>
    </Box>
  );
}
