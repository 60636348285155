import {
  Box,
  TextInput,
  Form,
  FormField,
  Button,
  Heading,
  Calendar,
  NameValueList,
  NameValuePair,
  Text,
  Spinner,
} from "grommet";
import React, { useState } from "react";
import { API } from "aws-amplify";
import { getAOAName } from "../graphql/queries";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// time range from 12:00am to 11:30pm in 30 minute increments
const times = [
  "12:00am",
  "12:30am",
  "1:00am",
  "1:30am",
  "2:00am",
  "2:30am",
  "3:00am",
  "3:30am",
  "4:00am",
  "4:30am",
  "5:00am",
  "5:30am",
  "6:00am",
  "6:30am",
  "7:00am",
  "7:30am",
  "8:00am",
  "8:30am",
  "9:00am",
  "9:30am",
  "10:00am",
  "10:30am",
  "11:00am",
  "11:30am",
  "12:00pm",
  "12:30pm",
  "1:00pm",
  "1:30pm",
  "2:00pm",
  "2:30pm",
  "3:00pm",
  "3:30pm",
  "4:00pm",
  "4:30pm",
  "5:00pm",
  "5:30pm",
  "6:00pm",
  "6:30pm",
  "7:00pm",
  "7:30pm",
  "8:00pm",
  "8:30pm",
  "9:00pm",
  "9:30pm",
  "10:00pm",
  "10:30pm",
  "11:00pm",
  "11:30pm",
];

const examAcronymToExamNameMapping = {
  AOACAMC: "Addiction Medicine Certification Examination",
  AOACAMR: "Addiction Medicine OCC/Recertification Examination",
  AOACPMR: "Pain Medicine Recertification/OCC Examination",
  AOACPMC: "Pain Medicine Initial Certification Examination",
  AOACSPMC: "Sports Medicines Certification Examination",
  AOACSPMR: "Sports Medicines OCC/Recertification Examination",
  AOBACCMC: "Anesthesiology Critical Care Medicine Certification Examination",
  AOBAPMC: "Pain Management Certification Examination",
  AOBAPMR: "Pain Management OCC/Recertification Examination",
  AOBAPEDC: "Pediatric Anesthesiology Certification Examination",
  AOBAPEDR: "Pediatric Anesthesiology OCC/Recertification Examination",
  AOBAAC: "Anesthesiology Written Certification Examination",
  AOBDPI: "Dermatology Initial Certification Examination",
  AOBDMOHSCA: "MOHS Micrographic Surgery Initial Certification Examination",
  AOBDMOHSRA: "MOHS Micrographic Surgery OCC/Recertification Examination",
  AOBDPEDC: "Pediatric Dermatology Initial Certification Examination",
  AOBEMC: "Emergency Medicine Certification Examination",
  AOBEMR: "Emergency Medicine OCC/Recertification Examination",
  AOBEMEMS: "Emercengy Medicine Service Examination",
  AOBEMMT: "Medical Toxicology Examination",
  AOBFPC: "AOBFP Certification Examination",
  AOBFPR: "AOBFP OCC/Recertification Examination",
  AOBFPEEIC: "Early Entry Initial Certification Examination",
  AOBFPGC: "Geriatric Certification Examination",
  AOBFPGR: "Geriatric OCC/Recertification Examination",
  AOBIMHFT: "Advanced Heart Failure Certification Examination",
  AOBIMCAC: "Cardiology Certification Examination",
  AOBIMCAR: "Cardiology OCC/Recertification Examination",
  AOBIMCLC: "Clinical Cardiac Electrophysiology Certification Examination",
  AOBIMCLR:
    "Clinical Cardiac Electrophysiology OCC/Recertification Examination",
  AOBIMCRC: "Critical Care Medicine Certification Examination",
  AOBIMCRR: "Critical Care Medicine OCC/Recertification Examination",
  AOBIMENC: "Endocrinology Certification Examination",
  AOBIMENR: "Endocrinology OCC/Recertification Examination",
  AOBIMGAC: "Gastroenterology Certification Examination",
  AOBIMGAR: "Gastroenterology OCC/Recertification Examination",
  AOBIMGEC: "Geriatric Medicine Certification Examination",
  AOBIMGER: "Geriatric Medicine OCC/Recertification Examination",
  AOBIMHEC: "Hematology Certification Examination",
  AOBIMHER: "Hematology OCC/Recertification Examination",
  AOBIMHPC: "Hospice and Palliative Medicine Certification Examination",
  AOBIMHPR: "Hospice and Palliative Medicine OCC/Recertification Examination",
  AOBIMIDC: "Infectious Disease Certification Examination",
  AOBIMIDR: "Infectious Disease OCC/Recertification Examination",
  AOBIMIMC: "Internal Medicine Certification Examination",
  AOBIMINC: "Interventional Cardiology Certification Examination",
  AOBIMINR: "Interventional Cardiology OCC/Recertification Examination",
  AOBIMNEC: "Nephrology Certification Examination",
  AOBIMNER: "Nephrology OCC/Recertification Examination",
  AOBIMONC: "Oncology Certification Examination",
  AOBIMONR: "Oncology OCC/Recertification Examination",
  AOBIMPUC: "Pulmonary Disease Certification Examination",
  AOBIMPUR: "Pulmonary Disease OCC/Recertification Examination",
  AOBIMRHC: "Rheumatology Certification Examination",
  AOBIMRHR: "Rheumatology OCC/Recertification Examination",
  AOBIMSLC: "Sleep Medicine Certification Examination",
  AOBIMSLR: "Sleep Medicine OCC/Recertification Examination",
  AOBNMMC: "Neuromusculoskeletal Medicine Certification Examination",
  AOBNMMR: "Neuromusculoskeletal Medicine OCC/Recertification Examination",
  AOBNPCPC: "Child-Adolescent Psychiatry Certification Examination",
  AOBNPCPR: "Child-Adolescent Psychiatry Recertification/OCC Examination",
  AOBNPNPR: "Child/Adolescent Neurology OCC/Recertification Examination",
  AOBNPGPC: "Geriatric Psychiatry Certification Examination",
  AOBNPGPR: "Geriatric Psychiatry Recertification/OCC Examination",
  AOBNPNC: "Neurology Certification Examination",
  AOBNPNEC: "Neurophysiology Certification Examination",
  AOBNPNER: "Neurophysiology Recertification/OCC Examination",
  AOBNPPC: "Psychiatry Certification Examination",
  AOBOGC: "Obstetrics and Gynecology Certification Examination",
  AOBOGR: "Obstetrics and Gynecology OCC/Recertification Examination",
  AOBOOOPH: "Ophthalmology Certification Examination",
  AOBOOOPHR: "Ophthalmology OCC/Recertification Examination",
  AOBOOENTC: "Otolaryngology Certification Examination",
  AOBOOENTR: "Otolaryngology OCC/Recertification Examination",
  AOBOSHSC: "Hand Surgery Certification Examination",
  AOBOSHSR: "Hand Surgery OCC/Recertification Examination",
  AOBOSOSC: "Orthopedic Sports Medicine Certification Examination",
  AOBOSC: "Orthopedic Surgery Certification Examination",
  AOBPEC: "General Pediatrics Certification Examination",
  AOBPENC: "Neonatology Certification Examination",
  AOBPENR: "Neonatology OCC/Recertification Examination",
  AOBPEPAAIC:
    "Pediatric and Adult Allergy and Immunology Certification Examination",
  AOBPEPAAIR:
    "Pediatric and Adult Allergy and Immunology OCC/Recertification Examination",
  AOBPMOMCAQC: "Occupational Medicine CAQ Examination",
  AOBPMPHCMR:
    "Public Health/Community Medicine Recertification/OCC Examination",
  AOBPMUHMR: "Undersea and Hyperbaric Medicine Recertification/OCC Examination",
  AOBPMRC: "Physical Medicine and Rehabilitation Certification Examination",
  AOBRC: "General Radiology Certification Examination",
  AOBRNCAQ: "Neuroradiology CAQ Examination",
  AOBRPRC: "Pediatric Radiology Certification Examination",
  AOBSCCC: "Cardiothoracic Surgery Certification Examination",
  AOBSCCR: "Cardiothoracic Surgery OCC/Recertification Examination",
  AOBSGSC: "General Surgery Certification Examination",
  AOBSGSR: "General Surgery OCC/Recertification Examination",
  AOBSVSC: "General Vascular Surgery Certification Examination",
  AOBSVSR: "General Vascular Surgery OCC/Recertification Examination",
  AOBSNSC: "Neurological Surgery Certification Examination",
  AOBSNSR: "Neurological Surgery OCC/Recertification Examination",
  AOBSPSC: "Plastic and Reconstructive Surgery Certification Examination",
  AOBSPSR: "Plastic and Reconstructive Surgery OCC/Recertification Examination",
  AOBSSCC: "Surgical Critical Care Certification Examination",
  AOBSSCR: "Surgical Critical Care OCC/Recertification Examination",
  AOBSUSC: "Urological Surgery Certification Examination",
  AOBSUSR: "Urological Surgery OCC/Recertification Examination",
  DOECC:
    "Designation of Osteopathic Manipulative Treatment Certification Examination",
};

const ExamStatusMapping = {
  eligible: "Schedule",
  scheduled: "Reschedule",
  rescheduled: "Reschedule",
  cancelled: "Cancelled",
  canceled: "Cancelled",
};

export default function NewAOAScheduling() {
  const [examItems, setExamItems] = useState([]);
  const [examAttempt, setExamAttempt] = useState({});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  if (step === 1) {
    return (
      <>
        <StepOne
          setStep={setStep}
          setExamItems={setExamItems}
          setIsLoading={setIsLoading}
        />
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Spinner size="large" background="white" />
          </div>
        )}
      </>
    );
  } else if (step === 2) {
    return (
      <>
        <StepTwo
          examItems={examItems}
          setStep={setStep}
          setExamAttempt={setExamAttempt}
        />
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Spinner size="large" background="white" />
          </div>
        )}
      </>
    );
  } else if (step === 3) {
    return (
      <>
        <StepThree
          examAttempt={examAttempt}
          setExamAttempt={setExamAttempt}
          setStep={setStep}
          setIsLoading={setIsLoading}
        />
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Spinner size="large" background="white" />
          </div>
        )}
      </>
    );
  } else if (step === 4) {
    return (
      <>
        <StepFour examAttempt={examAttempt} setStep={setStep} />{" "}
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Spinner size="large" background="white" />
          </div>
        )}
      </>
    );
  }
}

// using aoa id get the available exam attempts for the user
function StepOne({ setStep, setExamItems, setIsLoading }) {
  async function handleStep1Submit(value) {
    setIsLoading(true);
    try {
      const data = await API.graphql({
        query: getAOAName,
        variables: {
          action: "getNameOfFirstExam",
          payload: JSON.stringify({
            AOAID: value.aoaID,
          }),
        },
        authMode: "API_KEY",
      });

      const res = JSON.parse(data.data.getAOAExamSetup);
      const tempExams = res.data.aoaByAOAID.items;

      if (tempExams.length === 0) {
        alert("No exams found for AOA ID, or AOA ID is invalid");
      } else {
        setExamItems(tempExams);
        setStep(2);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Box align="center" justify="center" pad="small" gap="medium">
      <Form
        onSubmit={({ value }) => {
          handleStep1Submit(value);
        }}
      >
        <FormField
          name="aoaID"
          label="Please enter your AOA ID"
          htmlFor="aoa-id-input"
        >
          <TextInput
            name="aoaID"
            a11yTitle="Please enter your AOA ID"
            placeholder="AOA ID"
            id="aoa-id-input"
            style={{ border: "1px solid black" }}
            size="large"
          />
        </FormField>
        <Button label="Verify" type="submit" primary size="large" />
      </Form>
    </Box>
  );
}

// list the available exam attempts for the user to select
function StepTwo({ examItems, setStep, setExamAttempt }) {
  return (
    <Box align="center" justify="center" pad="small" gap="medium">
      <Heading level={2}>Select an exam to schedule</Heading>
      {examItems.map((item) => (
        <Box
          key={item.ExamAttempt}
          align="center"
          justify="center"
          pad="small"
          gap="small"
        >
          <Text weight="bold">
            {examAcronymToExamNameMapping[item.ExamType] || item.ExamType}
          </Text>
          <Button
            label={ExamStatusMapping[item.Status]}
            disabled={ExamStatusMapping[item.Status] === "Cancelled"}
            onClick={() => {
              setExamAttempt(item);
              setStep(3);
            }}
            primary
            color="black"
            pad="small"
          />
        </Box>
      ))}
    </Box>
  );
}

// using the selected exam attempt, display the available dates and times for the user to select

function StepThree({ examAttempt, setExamAttempt, setStep, setIsLoading }) {
  const [selectedDate, setSelectedDate] = useState(
    examAttempt.ScheduledDate || examAttempt.StartDate
  );
  const [selectedTime, setSelectedTime] = useState(
    dayjs(selectedDate).format("h:mma")
  );

  const windowstart = dayjs(examAttempt.StartDate).toISOString();
  const windowend = dayjs(examAttempt.EndDate).toISOString();

  function handleTimeSelect(time) {
    setSelectedTime(time);

    // adjust time of selectedDate to match selectedTime
    // handle hours depending on if it is am or pm
    const [hours, minutes] = time.split(":");
    const amOrPm = time.slice(-2);

    let formattedHours;
    if (amOrPm === "am" && hours === "12") {
      formattedHours = 0;
    } else if (amOrPm === "pm" && hours !== "12") {
      formattedHours = parseInt(hours) + 12;
    } else {
      formattedHours = parseInt(hours);
    }

    const newDate = dayjs(selectedDate)
      .hour(formattedHours)
      .minute(parseInt(minutes))
      .toISOString();

    setSelectedDate(newDate);
  }

  function handleDateSelect(date) {
    const [hours, minutes] = selectedTime.split(":");
    const amOrPm = selectedTime.slice(-2);

    let formattedHours;
    if (amOrPm === "am" && hours === "12") {
      formattedHours = 0;
    } else if (amOrPm === "pm" && hours !== "12") {
      formattedHours = parseInt(hours) + 12;
    } else {
      formattedHours = parseInt(hours);
    }

    const newDate = dayjs(date)
      .hour(formattedHours)
      .minute(parseInt(minutes))
      .toISOString();

    setSelectedDate(newDate);
  }

  async function handleSubmit() {
    setIsLoading(true);
    try {
      const data = await API.graphql({
        query: getAOAName,
        variables: {
          action: "updateExam",
          payload: JSON.stringify({
            input: {
              ExamAttempt: examAttempt.ExamAttempt,
              ScheduledDate: selectedDate,
              Status:
                examAttempt.Status === "eligible" ? "scheduled" : "rescheduled",
            },
            day: dayjs(selectedDate).format("LLLL z"),
          }),
        },
        authMode: "API_KEY",
      });

      const res = JSON.parse(data.data.getAOAExamSetup);
      setExamAttempt(res.data.updateAOAExam);
      setStep(4);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      align="center"
      justify="center"
      pad="small"
      textAlign="center"
      gap="small"
    >
      <NameValueList pairProps={{ direction: "column" }}>
        <NameValuePair
          name="Name"
          value={examAttempt.FirstName + " " + examAttempt.LastName}
        >
          <Text>
            {examAttempt.FirstName} {examAttempt.LastName}
          </Text>
        </NameValuePair>
        <NameValuePair name="Email" value={examAttempt.Email}>
          <Text>{examAttempt.Email}</Text>
        </NameValuePair>
        <NameValuePair name="AOAID" value={examAttempt.AOAID}>
          <Text>{examAttempt.AOAID}</Text>
        </NameValuePair>
        <NameValuePair name="Exam" value={examAttempt.ExamType}>
          <Text>{examAttempt.ExamType}</Text>
        </NameValuePair>
        <NameValuePair name="Exam Window">
          <Text>
            {dayjs(examAttempt.StartDate).format("LL") +
              " - " +
              dayjs(examAttempt.EndDate).format("LL")}
          </Text>
        </NameValuePair>
      </NameValueList>
      <Text weight="bold">Select a date</Text>
      <Text>{dayjs(selectedDate).format("LLLL z")}</Text>

      <Calendar
        size="medium"
        date={selectedDate}
        bounds={[windowstart, windowend]}
        onSelect={(date) => handleDateSelect(date)}
      />
      <Box width="medium" gap="small">
        <Text weight="bold">Select a time</Text>
        <select
          value={selectedTime}
          onChange={(e) => handleTimeSelect(e.target.value)}
          style={{ width: "100%", height: "50px", fontSize: "1.5rem" }}
          label="Select a time"
        >
          {times.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </select>
        <Button
          label="Submit"
          primary
          onClick={() => {
            handleSubmit();
          }}
          margin={{ bottom: "xlarge" }}
        />
      </Box>
    </Box>
  );
}

// display the final info
function StepFour({ examAttempt, setStep }) {
  return (
    <Box align="center" justify="center" pad="small" gap="medium">
      <Heading level="4" textAlign="center">
        Exam: {examAttempt.ExamType} has been scheduled for{" "}
        {dayjs(examAttempt.ScheduledDate).format("LLLL z")}
      </Heading>
      <Heading level="4" textAlign="center">
        Save this information for your records.
      </Heading>
      <Heading level="5" textAlign="center">
        If you would like to reschedule your exam click below.
      </Heading>
      <Button
        label="Reschedule"
        onClick={() => setStep(3)}
        primary
        size="large"
      />
    </Box>
  );
}
