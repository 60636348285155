import { API } from "aws-amplify";
import React, { useEffect } from "react";
import { ListExtensionSessionsByProctor } from "../graphql/queries";
import { useUser } from "../AuthContext";
import { onCreateExtensionSession } from "../graphql/subscriptions";
import { Box, Card, Text, Button } from "grommet";

export default function MyExtensionSessions() {
  const [sessions, setSessions] = React.useState([]);
  const user = useUser();
  useEffect(() => {
    getMySessions();

    const sub = API.graphql({
      query: onCreateExtensionSession,
      variables: {
        filter: {
          proctor: {
            eq: user.user.attributes.email,
          },
        },
      },
    }).subscribe({
      next: (data) => {
        console.log(data);
        setSessions((sessions) => [
          data.value.data.onCreateExtensionSession,
          ...sessions,
        ]);
      },
      error: (error) => {
        console.error(error);
      },
    });
    return () => sub.unsubscribe();
  }, []);

  async function getMySessions() {
    try {
      const data = await API.graphql({
        query: ListExtensionSessionsByProctor,
        variables: {
          proctor: user.user.attributes.email,
          sortDirection: "DESC",
        },
      });
      console.log(data);
      setSessions(data.data.extensionSessionByProctor.items);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div>
      <Card pad="small" margin="small" width="full">
        <Box direction="row" justify="between">
          <Text weight="bold">Tester</Text>
          <Text weight="bold">Exam</Text>
          <Text weight="bold">Status</Text>
          <Text weight="bold">Action</Text>
        </Box>
      </Card>
      {sessions.map((session) => (
        <Card
          key={session.session_code}
          pad="small"
          margin="small"
          width="full"
        >
          <Box direction="row" justify="between">
            <Text>{session.candidate_name}</Text>
            <Text>{session.exam_name}</Text>
            <Text>{session.status}</Text>
            <Button
              label="View Session"
              onClick={() =>
                window.open(`/extension/${session.session_code}`, "_blank")
              }
            />
          </Box>
        </Card>
      ))}
    </div>
  );
}
